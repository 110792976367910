import React from "react"

export function Authenticity({ size = null, scale = 1, color = "#fff" }) {
  return (
    <svg
      width={size || 33 * scale}
      height={size || 40 * scale}
      viewBox="0 0 33 40"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path d="M0 0V0.955937V33.4578V34.4137H0.955937H16.6542L16.3854 34.8917L15.6833 36.0866L17.0426 36.3107L19.5519 36.7289L21.2397 38.835L22.1807 40L22.8379 38.6557L23.8984 36.5347L24.9589 38.6557L25.6161 40L26.5571 38.835L28.245 36.7289L30.7543 36.3107L32.1135 36.0866L31.4115 34.8917L29.6938 31.9193C30.5535 30.737 31.068 29.29 31.068 27.7222C31.068 24.0976 28.3541 21.0969 24.8544 20.6273V7.6475V7.24421L24.5706 6.96042L17.879 0.268857L17.6102 0H17.2069H0.955937H0ZM1.91187 1.91187H16.2509V7.6475V8.60344H17.2069H22.9425V20.6273C21.6954 20.7946 20.5571 21.2908 19.5967 22.0164V21.5086H4.30172V23.4205H18.1777C17.6451 24.1264 17.2414 24.9373 16.9978 25.8103H4.30172V27.7222H16.7289C16.7289 29.29 17.2434 30.737 18.1031 31.9193L17.7595 32.5019H1.91187V1.91187ZM18.1628 3.25616L21.5982 6.69156H18.1628V3.25616ZM4.30172 8.60344V10.5153H10.9933V8.60344H4.30172ZM4.30172 12.9052V14.817H20.0747V12.9052H4.30172ZM4.30172 17.2069V19.1187H20.0747V17.2069H4.30172ZM23.8984 22.4645C26.8135 22.4645 29.1561 24.8071 29.1561 27.7222C29.1561 30.6372 26.8135 32.9798 23.8984 32.9798C20.9834 32.9798 18.6408 30.6372 18.6408 27.7222C18.6408 24.8071 20.9834 22.4645 23.8984 22.4645ZM19.4772 33.3532C20.386 34.0701 21.4598 34.5772 22.6438 34.7872L21.8073 36.475L20.8215 35.2502L20.5975 34.9515L20.239 34.8917L18.7304 34.6378L19.4772 33.3532ZM28.3196 33.3532L29.0665 34.6378L27.5579 34.8917L27.1994 34.9515L26.9754 35.2502L25.9895 36.475L25.1531 34.7872C26.337 34.5772 27.4108 34.0701 28.3196 33.3532Z" />
    </svg>
  )
}
