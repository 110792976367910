import React from "react"
import styled from "styled-components"

import { Benefit } from "./Benefit"

import { benefits } from "data/benefits"

export function Benefits() {
  return (
    <div className="fv cv">
      <div className="mt5 ttu f5 mb3 fw4 tracked sans-serif black-60">
        Benefits
      </div>
      <div className="f ch pv4">
        <Container>
          {benefits.map(benefit => <Benefit {...benefit} />)}
        </Container>
      </div>
    </div>
  )
}

const Container = styled.div.attrs({
  className: "f flex-wrap ph4 ph5-ns ca",
})``
