import React from "react"

export function Logo({ size = null, scale = 1, color = "#fff" }) {
  return (
    <svg
      width={size || 192 * scale}
      height={size || 399 * scale}
      viewBox="0 0 192 300"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.7166 134.796C68.053 144.164 73.8533 157.143 73.8533 171.46H73.8223V201.089C73.8223 194.958 71.3409 189.387 67.3396 185.372L15.1367 132.991C5.76931 123.623 0 110.644 0 96.3274C0 43.1373 42.9906 0 96 0C149.009 0 191.969 43.1373 191.969 96.2963V107.532C191.969 101.401 189.488 95.8294 185.486 91.8145L142.961 49.1752C130.926 37.0993 114.332 29.6296 95.969 29.6296C59.275 29.6296 29.5289 59.5082 29.5289 96.3274C29.5289 102.459 32.0103 108.03 36.0116 112.045L58.7166 134.796ZM73.8533 138.22V96.2963C73.8533 84.0336 83.779 74.0741 96 74.0741C102.111 74.0741 107.632 76.564 111.664 80.61L185.486 154.684C189.488 158.699 191.969 164.27 191.969 170.401V140.772C191.969 126.455 186.2 113.508 176.832 104.108L132.539 59.6639C123.203 50.2957 110.268 44.4756 96 44.4756C67.4637 44.4756 44.3244 67.6937 44.3244 96.3274C44.3244 98.3504 45.1619 100.218 46.4957 101.556L67.3706 122.502C71.3719 126.517 73.8533 132.088 73.8533 138.22ZM118.147 203.641C118.147 215.904 108.221 225.864 96 225.864C89.8895 225.864 84.3373 223.374 80.336 219.359L6.51373 145.254C2.48142 141.239 0 135.699 0 129.536V159.166C0 173.483 5.76931 186.461 15.1367 195.829L59.43 240.274C68.7974 249.673 81.7008 255.462 95.969 255.462C124.505 255.462 147.645 232.244 147.645 203.61C147.645 201.556 146.807 199.72 145.473 198.382L124.598 177.435C120.597 173.42 118.116 167.849 118.116 161.718V203.641H118.147ZM133.283 165.173C123.947 155.805 118.147 142.826 118.147 128.509V98.8484C118.147 104.98 120.628 110.551 124.629 114.566L176.863 167.009C186.231 176.377 192 189.356 192 203.673C192 256.863 149.009 300 96 300C42.9906 300 0 256.863 0 203.673V192.437C0 198.568 2.48142 204.108 6.48271 208.154L49.0081 250.794C61.043 262.87 77.6375 270.339 96 270.339C132.694 270.339 162.44 240.461 162.44 203.641C162.44 197.51 159.959 191.939 155.957 187.924L133.283 165.173Z"
      />
    </svg>
  )
}
