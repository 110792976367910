import React from "react"

export function Efficiency({ size = null, scale = 1, color = "#fff" }) {
  return (
    <svg
      width={size || 38 * scale}
      height={size || 40 * scale}
      viewBox="0 0 38 40"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path d="M36.7929 31.228L33.9938 31.5095C35.8438 28.6336 36.9214 25.2103 36.9209 21.5388C36.9194 12.9538 31.0622 5.73741 23.1222 3.67227C22.7268 3.56988 22.3242 3.80617 22.2213 4.20099C22.1189 4.5958 22.3552 4.99898 22.75 5.10187C30.0506 7.0016 35.4455 13.6415 35.4441 21.5393C35.4436 24.9503 34.4364 28.1192 32.7065 30.7795L31.7741 27.9808C31.6451 27.5939 31.2271 27.3847 30.8407 27.5137C30.4533 27.6426 30.2445 28.0606 30.3735 28.4475L31.9562 33.1991L36.9406 32.6979C37.3463 32.6571 37.6421 32.2952 37.6013 31.8891C37.5609 31.4835 37.1986 31.1876 36.7929 31.228Z" />
      <path d="M17.963 22.7695H21.9013C22.3089 22.7695 22.6398 22.4392 22.6398 22.0311C22.6398 21.623 22.3089 21.2927 21.9013 21.2927H18.7015V17.1082C18.7015 16.7006 18.3707 16.3698 17.963 16.3698C17.5554 16.3698 17.2246 16.7001 17.2246 17.1082V22.0311C17.2246 22.2255 17.3034 22.4156 17.4407 22.5534C17.5781 22.6912 17.7686 22.7695 17.963 22.7695Z" />
      <path d="M9.8457 21.5388C9.8462 26.2972 13.7023 30.1533 18.4607 30.1538C23.2191 30.1533 27.0752 26.2972 27.0757 21.5388C27.0752 16.7804 23.2191 12.9243 18.4607 12.9238C13.7023 12.9243 9.8462 16.7804 9.8457 21.5388ZM18.4607 14.4007C20.4343 14.4011 22.2139 15.1986 23.5081 16.4914C24.8013 17.7851 25.5983 19.5652 25.5988 21.5388C25.5983 23.5124 24.8013 25.292 23.5081 26.5862C22.2139 27.8789 20.4343 28.6764 18.4607 28.6769C16.4871 28.6764 14.7075 27.8789 13.4133 26.5862C12.1201 25.292 11.3231 23.5124 11.3226 21.5388C11.3231 19.5652 12.1201 17.7851 13.4133 16.4914C14.7075 15.1986 16.4871 14.4011 18.4607 14.4007Z" />
      <path d="M29.7872 34.194C26.7799 36.8868 22.8145 38.5222 18.4602 38.5227C12.0118 38.5231 6.40761 34.9275 3.5307 29.6339L6.66015 29.8382C7.06678 29.8648 7.41827 29.5567 7.44486 29.1495C7.47193 28.7429 7.16327 28.3909 6.75664 28.3643L1.75896 28.0385L0.343639 32.8441C0.228444 33.235 0.451942 33.6456 0.843309 33.7613C1.23418 33.8765 1.64475 33.653 1.75994 33.2616L2.49148 30.778C5.68395 36.2872 11.6342 39.9995 18.4602 40C23.1882 40.0005 27.5075 38.2199 30.7728 35.2942C31.0765 35.022 31.1021 34.5553 30.8299 34.2516C30.5582 33.9474 30.0915 33.9218 29.7872 34.194Z" />
      <path d="M1.4291 26.5109C1.82539 26.4134 2.06711 26.0132 1.96963 25.6174C1.64768 24.3108 1.47686 22.9457 1.47686 21.5388C1.47735 16.8468 3.37659 12.6053 6.45091 9.52948C9.52031 6.46107 13.7515 4.5638 18.4322 4.55543L16.75 7.20097C16.5309 7.54508 16.6329 8.00143 16.9765 8.22049C17.3206 8.43956 17.7774 8.33766 17.996 7.99404L20.6834 3.76826L17.1493 0.217408C16.8618 -0.0715641 16.3946 -0.0725487 16.1051 0.214946C15.8161 0.502441 15.8152 0.970112 16.1032 1.25908H16.1027L17.9271 3.09186C7.97896 3.37493 0.000492286 11.5218 0 21.5388C0 23.0654 0.185592 24.5501 0.536099 25.9703C0.63308 26.3666 1.03331 26.6083 1.4291 26.5109Z" />
    </svg>
  )
}
