import React from "react"
import styled from "styled-components"

import { Trust } from "glyphs/Trust"
import { Efficiency } from "glyphs/Efficiency"
import { Authenticity } from "glyphs/Authenticity"
import { Integration } from "glyphs/Integration"
import { Privacy } from "glyphs/Privacy"
import { Verifiability } from "glyphs/Verifiability"

const icons = {
  trust: <Trust size={40} color="#232323" />,
  privacy: <Privacy color="#232323" />,
  authenticity: <Authenticity color="#232323" />,
  integration: <Integration color="#232323" />,
  verifiability: <Verifiability color="#232323" />,
  efficiency: <Efficiency color="#232323" />,
}

export function Benefit({ icon, title, description }) {
  return (
    <Container>
      {icons[icon]}
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  )
}

const Container = styled.div.attrs({
  className: "fv cv ph2-ns ph1 mh3 mb3 mt4",
})`
  max-width: 300px;
  min-width: 240px;
  min-height: 300px;
`

const Title = styled.div.attrs({
  className: "mt4 f4 mb3 sans-serif",
})``

const Description = styled.div.attrs({
  className: "sans-serif fw3 f5 lh-copy black-60 tc",
})``
