export const sections = [
  {
    key: 0,
    label: "Transparency Framework",
    title: "Make transparency an advantage",
    content: `OrigoChain has developed a platform that lets governments transparently record land titles and deeds on blockchain.

Land titles are written on blockchain and stored in an immutable, unduplicatable format that can be verified with the OrigoChain Property Explorer or any public block explorer.`,
    direction: "left",
    theme: "light",
  },
  {
    key: 1,
    label: "Privacy",
    title: "Data protection with leading industry standard encryption",
    content: `Identity, ownership, and financial records that need to be kept private are encrypted and only shared with users and institutions that are given access.

OrigoChain uses asymmetric key encryption to provide role based permissions to entities with registered keys. Users with access will be able to decrypt private information and invoke property management functions within OrigoChain.
`,
    direction: "right",
    theme: "dark",
  },
  {
    key: 2,
    label: "The OrigoChain Property Explorer",
    title:
      "Give confidence with immutable and public property ownership records",
    content: `With the OrigoChain Property Explorer governments can easily give stakeholders access to immutable, unduplicatable property information within their jurisdiction for greater record integrity.

The OrigoChain Property Explorer links to blockchain and provides an easy to use visual representation of land titles and status to property owners, banks and other institutions.`,
    direction: "left",
    theme: "light",
  },
  {
    key: 3,
    label: "Smart Contracts",
    title: "Enable rule based decision making",
    content: `OrigoChain converts land titles into smart contracts and lets governments automate time consuming validation processes like ownership verification, encumbrance checks, tax or fee collection, and more.

Data sources can also be linked to these smart contracts allowing them to process external information and make automated decisions.`,
    direction: "right",
    theme: "dark",
  },
  {
    key: 4,
    label: "Integration Layer",
    title: "Link stakeholders for interoperability",
    content: `The standardized data exchange format used by OrigoChain enables efficient sharing of data among individuals, institutions, and government agencies.

The digital versions of these land titles and deeds created in the blockchain are linked to QR codes enabling all stakeholders to easily reference property information.`,
    direction: "left",
    theme: "light",
  },
  {
    key: 5,
    label: "Transaction Process",
    title: "Streamline property transactions",
    content: `OrigoChain helps governments reduce the number of steps in a property transaction with its block explorer, property manager, and smart contracts. The guaranteed execution of the smart contracts eliminate the need for legacy steps designed to prevent human error and malpractice.`,
    direction: "right",
    theme: "dark",
  },
]
