import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

import "./styles/q.css"
import "./styles/custom.css"
import "tachyons"

ReactDOM.render(<App />, document.getElementById("root"))

serviceWorker.unregister()
