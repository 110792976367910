import React from "react"
import { Logo } from "glyphs/Logo"
import styled from "styled-components"

import { links } from "data/links"

export function Footer() {
  return (
    <div className="bg-black-70 pv5 fv cv">
      <Logo size={60} />
      <Links>
        {links.map(link => (
          <Link
            onClick={e => {
              e.preventDefault()
              alert("Other pages are not yet ready, please check back soon.")
            }}
            {...link}
          >
            {link.value}
          </Link>
        ))}
      </Links>
      <Copyright>
        Copyright © 2017-2019 OrigoChain. All Rights Reserved.
      </Copyright>
    </div>
  )
}

const Links = styled.div.attrs({
  className: "pt3 pb1",
})``

const Link = styled.a.attrs({
  className: "mh2 sans-serif white-70 f5 fw3 link",
})``

const Copyright = styled.div.attrs({
  className: "white-30 ph2 tc sans-serif f6 fw2",
})``
