import React from "react"

export function Trust({ size = null, scale = 1, color = "#fff" }) {
  return (
    <svg
      width={size || 49 * scale}
      height={size || 40 * scale}
      viewBox="0 0 49 40"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path d="M5.99992 27C5.82592 27 5.64792 26.956 5.48592 26.858L0.485932 23.858C0.0119333 23.574 -0.140066 22.96 0.143933 22.486C0.427932 22.012 1.04193 21.854 1.51593 22.142L6.51592 25.142C6.98992 25.426 7.14192 26.04 6.85792 26.514C6.66992 26.826 6.33792 27 5.99992 27Z" />
      <path d="M22.6319 7.00407C22.5359 7.00407 22.4379 6.99007 22.3399 6.96207C18.7839 5.87407 14.5539 5.70807 9.76989 6.47207C7.75589 6.78807 5.7159 6.23007 4.1659 4.94008L0.359908 1.76808C-0.0640907 1.41408 -0.122091 0.784085 0.231909 0.360086C0.585908 -0.0619127 1.21391 -0.123913 1.63991 0.232086L5.4459 3.40408C6.55189 4.32608 8.01989 4.73008 9.45589 4.49608C14.5419 3.69008 19.0759 3.87208 22.9239 5.04608C23.4519 5.20807 23.7499 5.76607 23.5879 6.29407C23.4579 6.72607 23.0619 7.00407 22.6319 7.00407Z" />
      <path d="M30.5019 38C29.3079 38 28.1339 37.588 27.2019 36.842L22.3519 32.716C21.9319 32.358 21.8799 31.728 22.2379 31.306C22.5959 30.886 23.2299 30.836 23.6479 31.192L28.4739 35.298C29.0319 35.744 29.7599 36 30.5019 36C31.3279 36 31.9999 35.328 31.9999 34.502C31.9999 34.068 31.8139 33.658 31.4879 33.376L22.3599 25.768C21.9359 25.414 21.8779 24.784 22.2319 24.36C22.5859 23.94 23.2139 23.878 23.6419 24.234L32.7439 31.818C32.7559 31.828 32.7699 31.84 32.7839 31.852L34.8859 33.604C35.1919 33.86 35.5839 34 35.9839 34H36.2798C37.2278 34 37.9998 33.228 37.9998 32.28C37.9998 31.78 37.7898 31.312 37.4178 30.986L26.3599 21.77C25.9359 21.416 25.8779 20.786 26.2319 20.362C26.5879 19.94 27.2159 19.88 27.6419 20.236L38.6618 29.42C38.6818 29.436 38.6998 29.452 38.7178 29.466L38.8798 29.6C39.1898 29.858 39.5858 30 39.9918 30H40.2598C41.2198 30 41.9998 29.218 41.9998 28.26C41.9998 27.746 41.7818 27.266 41.3998 26.936L26.3399 13.7521C25.9239 13.3881 25.8819 12.7581 26.2479 12.3421C26.6099 11.9241 27.2419 11.8841 27.6599 12.2481L42.7138 25.428C43.5298 26.136 43.9998 27.166 43.9998 28.252C43.9998 30.322 42.3218 32 40.2598 32H39.9918C39.9918 32 39.9918 32 39.9898 32C39.9978 32.09 39.9998 32.182 39.9998 32.274C39.9998 34.33 38.3318 36 36.2798 36H35.9839C35.2359 36 34.5079 35.774 33.8939 35.358C33.5119 36.874 32.1359 38 30.5019 38Z" />
      <path d="M24.9999 40C24.1999 40 23.4459 39.688 22.8779 39.122L20.3159 36.732C19.9119 36.356 19.8919 35.722 20.2679 35.32C20.6459 34.914 21.2759 34.894 21.6819 35.272L24.2679 37.686C24.6959 38.112 25.3279 38.088 25.7059 37.71C26.2179 37.198 26.4119 36.208 26.1519 35.948C25.7619 35.558 25.7619 34.924 26.1519 34.534C26.5419 34.144 27.1759 34.144 27.5659 34.534C28.7519 35.72 28.3079 37.934 27.1199 39.124C26.5539 39.688 25.7999 40 24.9999 40Z" />
      <path d="M41.9998 27C41.6598 27 41.3298 26.826 41.1418 26.514C40.8578 26.042 41.0118 25.426 41.4838 25.142L46.4838 22.1421C46.9578 21.8521 47.5718 22.0141 47.8558 22.486C48.1398 22.958 47.9858 23.574 47.5138 23.858L42.5138 26.858C42.3538 26.956 42.1758 27 41.9998 27Z" />
      <path d="M17.8659 20.0001H17.0659C15.4119 20.0001 13.9239 19.0801 13.1839 17.6021C12.3459 15.9281 12.6719 13.9141 13.9959 12.5901L19.2939 7.29408C19.3519 7.23608 19.4159 7.18408 19.4859 7.14408C19.8499 6.92609 20.2199 6.68409 20.5979 6.43609C22.3339 5.29209 24.2999 4.00009 26.9999 4.00009C28.5659 4.00009 30.0079 4.47409 31.5339 4.97809C33.7079 5.69409 36.1619 6.50609 39.5699 6.05009L46.3499 0.2401C46.7698 -0.1159 47.3998 -0.0678995 47.7598 0.3501C48.1198 0.768099 48.0718 1.4001 47.6518 1.7601L40.6519 7.76008C40.5099 7.88208 40.3359 7.96208 40.1519 7.98808C36.1319 8.60608 33.2359 7.64608 30.9099 6.87609C29.4799 6.40609 28.2459 6.00009 26.9999 6.00009C24.8979 6.00009 23.2699 7.07008 21.6959 8.10408C21.3299 8.34408 20.9739 8.57808 20.6219 8.79408L15.4099 14.0041C14.6979 14.7161 14.5219 15.8041 14.9719 16.7081C15.3699 17.5041 16.1719 18.0001 17.0639 18.0001H17.8639C18.4079 18.0001 18.9179 17.8201 19.3379 17.4781C19.7159 17.1701 20.1539 16.8041 20.6239 16.4121C23.1879 14.2681 25.2599 12.5841 26.6479 12.0641C27.1639 11.8641 27.7419 12.1321 27.9359 12.6481C28.1299 13.1661 27.8679 13.7421 27.3519 13.9361C26.1739 14.3801 23.6059 16.5261 21.9079 17.9481C21.4319 18.3461 20.9879 18.7181 20.5999 19.0321C19.8319 19.6561 18.8599 20.0001 17.8659 20.0001Z" />
      <path d="M7.95387 31C6.32587 31 4.99988 29.674 4.99988 28.044V27.59C4.99988 27.004 5.17188 26.438 5.49588 25.952L6.58787 24.316C7.13587 23.492 8.05387 23 9.04587 23C10.6739 23 11.9999 24.326 11.9999 25.956V26.41C11.9999 26.996 11.8279 27.562 11.5039 28.048L10.4119 29.684C9.86387 30.508 8.94587 31 7.95387 31ZM9.04587 25C8.72587 25 8.42987 25.158 8.25387 25.424L7.15987 27.062C7.05587 27.218 6.99987 27.402 6.99987 27.592V28.046C6.99987 28.574 7.42787 29.002 7.95387 29.002C8.27387 29.002 8.56987 28.844 8.74587 28.578L9.83787 26.94C9.94387 26.782 9.99987 26.598 9.99987 26.408V25.954C9.99987 25.428 9.57187 25 9.04587 25Z" />
      <path d="M11.0219 34C9.32588 34 7.99988 32.674 7.99988 31.044V30.652C7.99988 30.016 8.20988 29.384 8.58988 28.876L10.6139 26.18C11.1679 25.44 12.0519 24.998 12.9779 24.998C14.6739 25 15.9999 26.326 15.9999 27.956V28.348C15.9999 28.984 15.7899 29.616 15.4099 30.124L13.3859 32.82C12.8319 33.558 11.9479 34 11.0219 34ZM13.0459 27C12.6779 27 12.3939 27.142 12.2139 27.38L10.1899 30.078C10.0679 30.242 9.99987 30.446 9.99987 30.652V31.044C9.99987 31.572 10.4279 32 10.9539 32C11.3219 32 11.6059 31.858 11.7859 31.62L13.8099 28.922C13.9319 28.758 13.9999 28.554 13.9999 28.348V27.956C13.9999 27.428 13.5719 27 13.0459 27Z" />
      <path d="M15.0219 36C13.3259 36 11.9999 34.674 11.9999 33.044V32.652C11.9999 32.016 12.2099 31.384 12.5899 30.876L14.6139 28.18C15.1679 27.44 16.0519 26.998 16.9779 26.998C18.6739 27 19.9999 28.326 19.9999 29.956V30.348C19.9999 30.984 19.7899 31.616 19.4099 32.124L17.3859 34.82C16.8319 35.558 15.9479 36 15.0219 36ZM17.0459 29C16.6779 29 16.3939 29.142 16.2139 29.38L14.1899 32.078C14.0679 32.242 13.9999 32.446 13.9999 32.652V33.044C13.9999 33.572 14.4279 34 14.9539 34C15.3219 34 15.6059 33.858 15.7859 33.62L17.8099 30.922C17.9319 30.758 17.9999 30.554 17.9999 30.348V29.956C17.9999 29.428 17.5719 29 17.0459 29Z" />
      <path d="M19.0218 38C17.3258 38 15.9998 36.674 15.9998 35.044V34.652C15.9998 34.016 16.2098 33.384 16.5898 32.876L18.6138 30.18C19.1678 29.44 20.0518 28.998 20.9778 28.998C22.6758 28.998 23.9998 30.324 23.9998 31.954V32.346C23.9998 32.982 23.7898 33.614 23.4098 34.122L21.3858 36.818C20.8318 37.558 19.9478 38 19.0218 38ZM21.0458 31C20.6778 31 20.3938 31.142 20.2138 31.38L18.1898 34.078C18.0678 34.242 17.9998 34.446 17.9998 34.652V35.044C17.9998 35.572 18.4278 36 18.9538 36C19.3218 36 19.6058 35.858 19.7858 35.62L21.8098 32.922C21.9318 32.758 21.9998 32.554 21.9998 32.348V31.956C21.9998 31.428 21.5718 31 21.0458 31Z" />
    </svg>
  )
}
