import React from "react"

export function Integration({ size = null, scale = 1, color = "#fff" }) {
  return (
    <svg
      width={size || 52 * scale}
      height={size || 40 * scale}
      viewBox="0 0 52 40"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path d="M30.2083 12.039H18.1693V0H12.039V12.039H0V18.1693H12.039V30.2083H18.1693V18.1693H30.2083V12.039Z" />
      <path d="M51.9778 21.823H39.9388V9.78406H33.8085V21.823H21.7695V27.9534H33.8085V40H39.9388V27.9534H51.9778V21.823Z" />
    </svg>
  )
}
