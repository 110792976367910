import React from "react"
import styled from "styled-components"

export function ContentSection({
  label,
  title,
  content,
  direction,
  theme,
  image,
}) {
  const orderLastInMobileLayout = direction === "right"
  const orderFirstInDesktopLayout = direction !== "right"

  return (
    <Container theme={theme}>
      <SubContainer
        orderLastInMobileLayout={orderLastInMobileLayout}
        className=""
      >
        <GraphicBlock src={image} />
      </SubContainer>
      <SubContainer
        orderFirstInDesktopLayout={orderFirstInDesktopLayout}
        className=""
      >
        <div className="fv sans-serif ph4" style={{ maxWidth: "500px" }}>
          <Label theme={theme}>{label}</Label>
          <Title theme={theme}>{title}</Title>
          <Content theme={theme}>{content}</Content>
        </div>
      </SubContainer>
    </Container>
  )
}

const GraphicBlock = styled.div.attrs({
  className: "mh3",
})`
  width: 400px;
  height: 400px;
  background-image: url(${p => p.src});
  background-size: contain;
  background-repeat: no-repeat;
`

const Label = styled.div.attrs({
  className: "ttu f5 mb3 fw4 tracked",
})`
  color: ${p =>
    p.theme === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.5)"};
`

const Title = styled.div.attrs({
  className: "f3 f2-ns fw3 mb3",
})`
  color: ${p =>
    p.theme === "dark" ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.6)"};
`

const Content = styled.div.attrs({
  className: "f4 fw3 lh-copy",
})`
  white-space: pre-wrap;
  color: ${p =>
    p.theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)"};
`

const Container = styled.div.attrs({
  className: "f pv6-ns pv0",
})`
  flex-shrink: 1 0 auto;
  @media screen and (max-width: 30em) {
    flex-direction: column;
  }

  ${p => p.theme === "dark" && "background: #4a4a4a;"};
`

const SubContainer = styled.div.attrs({
  className: "f ca pv5 pv0-ns",
})`
  flex: 1 0 auto;

  @media screen and (max-width: 30em) {
    ${p => p.orderLastInMobileLayout && "order: 10;"};
  }
  ${p => p.orderFirstInDesktopLayout && "order: -1;"};
`
