import React, { Component } from "react"
import { Welcome } from "./sections/Welcome"
import { ContentSection } from "common/ContentSection"
import { Benefits } from "./sections/Benefits"
import { RequestForDemo } from "./sections/RequestForDemo"
import { Footer } from "./sections/Footer"

import { sections } from "data/sections"

export default class App extends Component {
  render() {
    return (
      <div>
        <Welcome />
        {sections.map(section => (
          <ContentSection {...section} image={`${section.key}.png`} />
        ))}
        <Benefits />
        <RequestForDemo />
        <Footer />
      </div>
    )
  }
}
