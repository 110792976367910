import React from "react"

function isEmailValid(email) {
  return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null
}

export class RequestForDemo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      valid: false,
      registered: false,
      error: false,
      pristine: true,
    }
  }

  onChange = (e) => {
    let email = e.target.value.trim()
    let valid = isEmailValid(email)
    let error = !valid

    this.setState({email, valid, error })
  }

  registerEmail = () => {
    this.setState({pristine: false}, () => {
      if(!this.state.valid) {
        return
      }

      fetch("https://origochain.com/api/add-email", {
        method: "POST",
        body: JSON.stringify({ email: this.state.email }),
        mode: "cors",
        headers: {
          "content-type": "application/json",
        },
      })
        .then(response => response.json())
        .then(response => {
          if(!response.error) {
            this.setState({registered: true})
          } else {
            this.setState({error: true})
          }
        })
        .catch(error => {
          console.error(error)
          this.setState({error: true})
        })
    })
  }

  okay() {
    return (
      <Notification type="green">
        We'll get in touch with you shortly
      </Notification>
    )
  }

  error() {
    return (
      <Notification type="red">
        Please enter a valid email address
      </Notification>
    )
  }

  render() {
    let message = null
    let showButton = true

    if(this.state.registered) {
      message = this.okay()
      showButton = false
    } else if(!this.state.pristine && (this.state.error || !this.state.valid)) {
      message = this.error()
    }

    return (
      <div className="f ch pv5 bg-black-40">
        <div className="pv5 fv cv sans-serif">
          <div className="mb3 f2 fw2 white">Request for a Demo</div>
          <div className="mb4 black-80 fw2 tc">
            See how OrigoChain can empower your government.
          </div>
          {message}
          {showButton && <Field onChange={this.onChange} registerEmail={this.registerEmail}/>}
        </div>
      </div>
    )
  }
}

function Notification({ type = "normal", children }) {
  const typeClasses = {
    red: "oc-warn",
    green: "oc-success",
    normal: "black-60",
  }

  const className = `fw1 mt2 f6 ${typeClasses[type]}`

  return <div className={className}>{children}</div>
}

function Field({onChange, registerEmail}) {
  return (
    <div>
      <div className="pa2 f br1" style={{ background: "#fff" }}>
        <input className="outline-0 bn bg-transparent w-100 w5-ns" onChange={onChange}/>
        <div className="pa2 bg-black-70 fw2 br1 usn pointer tr2 hover-bg-black-90 f6 white-80" onClick={registerEmail}>
          Request
        </div>
      </div>
    </div>
  )
}
