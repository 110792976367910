import React from "react"
import styled from "styled-components"
import { Logo } from "glyphs/Logo"
import { Menu } from "glyphs/Menu"

export function NavBar() {
  return (
    <div className="ph2 pv3 bg-white-10 f cv justify-between">
      <div className="f cv">
        {false && (
          <SmallScreenButton>
            <Menu size={10} />
          </SmallScreenButton>
        )}
        <Logo size={30} />
        <div className="ml1 sans-serif f4 white usn lh-copy fw3">
          OrigoChain
        </div>
      </div>
      {false && (
        <Controls>
          <div className="mr3 hover-white-90 pointer usn">For Governments</div>
          <div className="mr3 hover-white-90 pointer usn">Technology</div>
          <div className="mr3 hover-white-90 pointer usn">Blog</div>
          <div className="hover-white-90 pointer usn">About</div>
        </Controls>
      )}
    </div>
  )
}

const SmallScreenButton = styled.div.attrs({
  className: "dn mr2",
})`
  @media screen and (max-width: 30em) {
    display: block !important;
  }
`

const Controls = styled.div.attrs({
  className: "f sans-serif white-80 fw1 f6",
})`
  letter-spacing: 0.06em;

  @media screen and (max-width: 30em) {
    display: none !important;
  }
`
