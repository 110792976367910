import React from "react"

export function Privacy({ size = null, scale = 1, color = "#fff" }) {
  return (
    <svg
      width={size || 41 * scale}
      height={size || 40 * scale}
      viewBox="0 0 41 40"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path d="M5.91942 39.5453L38.2938 2.32575C38.8077 1.73846 38.7343 0.857527 38.147 0.343648C37.5597 -0.170231 36.6788 -0.0968208 36.1649 0.49047L28.0896 9.7403C25.6671 8.71254 23.1711 8.19866 20.6017 8.19866C8.78246 8.19866 0.560388 19.2104 0.266743 19.6508C-0.100314 20.1647 -0.100314 20.8988 0.340154 21.4127C3.57025 25.3769 6.87376 28.3134 10.3241 30.1487L3.79049 37.6366C3.27661 38.2239 3.35002 39.1048 3.93731 39.6187C4.23096 39.8389 4.5246 39.9858 4.89166 39.9858C5.25871 40.0592 5.62577 39.839 5.91942 39.5453ZM16.8577 22.7341C16.4907 22.0734 16.2704 21.3393 16.2704 20.6052C16.2704 18.3294 18.1057 16.4941 20.3815 16.4941C20.9687 16.4941 21.4826 16.641 21.9965 16.8612L16.8577 22.7341ZM3.27661 20.5318C5.40554 18.0358 12.086 11.0617 20.6751 11.0617C22.5104 11.0617 24.3457 11.3553 26.1809 12.0161L23.9786 14.5854C22.9508 13.9982 21.7029 13.6311 20.4549 13.6311C16.6375 13.6311 13.4808 16.7878 13.4808 20.6052C13.4808 22.2202 14.0681 23.7619 15.0224 24.9364L12.3062 28.0931C9.14952 26.4781 6.13965 23.9821 3.27661 20.5318ZM40.4227 19.6508C40.8632 20.1647 40.8632 20.8254 40.4962 21.4127C40.1291 21.8532 31.907 32.8649 20.1612 32.8649C18.7664 32.8649 17.3716 32.7181 15.9768 32.351L18.1057 29.855C18.7664 29.9284 19.5005 30.0018 20.1612 30.0018C28.7503 30.0018 35.4308 23.0278 37.5597 20.5318C35.5042 18.1092 33.4487 16.2005 31.3197 14.7323L33.155 12.6033C35.651 14.3652 38.0736 16.7878 40.4227 19.6508Z" />
    </svg>
  )
}
