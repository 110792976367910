export const benefits = [
  {
    key: 0,
    icon: "trust",
    title: "Trust",
    description:
      "Land titles and deeds are decentrally stored on a global network that's open to the public. These land titles and deeds cannot be changed or duplicated letting governments create trust in property ownership.",
  },
  {
    key: 1,
    icon: "verifiability",
    title: "Verifiable",
    description:
      "Ownership and property information are stored in a blockchain and referenced with a QR code. Easily letting governments show property information in a secure, auditable format.",
  },
  {
    key: 2,
    icon: "efficiency",
    title: "Efficiency",
    description:
      "Smart contracts can replace manual checks and processes with code based contracts that are always honoured. These contracts consistently execute every time and cannot be tampered with or fail, letting governments reduce the number of steps in the transaction process.",
  },
  {
    key: 3,
    icon: "authenticity",
    title: "Authenticity",
    description:
      "Blockchain makes the duplication of land titles and deeds in OrigoChain impossible letting governments demonstrate true property ownership and tackle counterfeits or false claims.",
  },
  {
    key: 4,
    icon: "integration",
    title: "Integration",
    description:
      "OrigoChain is a platform on top of blockchain that integrates with existing software and infrastructure. Governments can easily link to OrigoChain using our SDK or directly connect to the API.",
  },
  {
    key: 5,
    icon: "privacy",
    title: "Privacy",
    description:
      "Information in OrigoChain is access restricted letting authorities, banks, insurance agencies and other institutions securely share information. Role based access can be configured for each stakeholders to protect privacy and only share required information.",
  },
]
