import React from "react"
import styled from "styled-components"
import { NavBar } from "common/NavBar"

export function Welcome() {
  return (
    <div>
      <ImageBackground>
        <NavBar />
        <div className="f ca" style={{ flexGrow: 1 }}>
          <div
            className="sans-serif tc ph4"
            style={{ height: 400, maxWidth: 800 }}
          >
            <div className="white-80 f1 fw3 mb3">
              A Platform for Governments
            </div>
            <div className="white-70 f4 lh-copy tracked fw2">
              OrigoChain makes it easy for a government to bring trustworthy
              land titles and deeds to its property owners and investors,
              helping build trust and reduce the complexity of transactions.
            </div>
          </div>
        </div>
      </ImageBackground>
    </div>
  )
}

const ImageBackground = styled.div.attrs({
  className: "fv",
})`
  background-image: url(farm.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 800px;
  @media screen and (max-width: 30em) {
    height: 600px;
  }
`
