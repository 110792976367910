export const links = [
  {
    key: 0,
    href: "/about",
    value: "About",
  },
  {
    key: 1,
    href: "/careers",
    value: "Careers",
  },
  {
    key: 2,
    href: "/partnership",
    value: "Partnership",
  },
  {
    key: 3,
    href: "/terms",
    value: "Terms",
  },
  {
    key: 4,
    href: "/privacy",
    value: "Privacy",
  },
]
